@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  color: #444;
}

h2 {
  font-weight: 200;
}

h3 {
  font-weight: 200;
}

h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 1.2em;
  color: #DDD;
}

a:hover {
  text-decoration: underline;
}

.content {
  width: 90%;
}

a {
  text-decoration: none;
  color: #444;
}

.siteName {
  font-weight: 100;
}

body {
  margin: 0;
  font-size: 1.2em;
  font-weight: 300;
  color: #444;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100vh;
  /* background: linear-gradient(50deg, #fe6b8b 30%, #ff8e53 60%) no-repeat; */
  background: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
